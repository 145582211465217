import { Component } from '@angular/core';
import {HelperService} from '../../helper.service';
import {Observable} from 'rxjs';
import {CrmDataService} from '../../crm-data.service';
import {GalleryImage} from '../../models/gallery-image';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'nbd-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent {

  images$: Observable<GalleryImage[]>;

  selectedImage?: GalleryImage;

  constructor(private hs: HelperService, private crm: CrmDataService) {
    this.images$ = this.crm.getImageGallery();
  }

  protected readonly environment = environment;
}
