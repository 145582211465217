<h2 mat-dialog-title>Parole Chiave</h2>
<mat-dialog-content class="mat-typography">
  @if (keywords$ | async; as keywords) {
    <div class="grid w-full h-full grid-cols-[1fr_1fr_max-content] auto-rows-max items-center">
      @for (k of keywords; track k) {
        <div>{{k.key}}</div>
        <div class="font-mono"><span>{{</span>{{k.value}}<span>&#125;&#125;</span></div>
        <button mat-icon-button [cdkCopyToClipboard]="'{{' + k.value + '}}'" mat-dialog-close>
          <mat-icon>content_copy</mat-icon>
        </button>
      }
    </div>
  } @else {
    <nbd-loader [fill]="true"></nbd-loader>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
