import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'nbd-json-viewer-dialog',
  templateUrl: './json-viewer-dialog.component.html',
  styleUrls: ['./json-viewer-dialog.component.scss']
})
export class JsonViewerDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, json: string}) {
  }

  protected readonly JSON = JSON;
}
