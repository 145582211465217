import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Directive,
  DoCheck, ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[nbdCheckLifecycle]'
})
export class CheckLifecycleDirective implements OnChanges, OnInit, DoCheck,
  AfterContentInit, AfterContentChecked,
  AfterViewInit, AfterViewChecked,
  OnDestroy {

  constructor() {
  }

  ngAfterContentChecked(): void {
    console.log(`ngAfterContentChecked`);
  }

  ngAfterContentInit(): void {
    console.log(`ngAfterContentInit`);
  }

  ngAfterViewChecked(): void {
    console.log(`ngAfterViewChecked`);
  }

  ngAfterViewInit(): void {
    console.log(`ngAfterViewInit`);
  }

  ngDoCheck(): void {
    console.log(`ngDoCheck`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(`ngOnChanges`, changes);
  }

  ngOnDestroy(): void {
    console.log(`ngOnDestroy`);
  }

  ngOnInit(): void {
    console.log(`ngOnInit`);
  }

}
