<div class="h-full w-full grid grid-cols-1 grid-rows-[1fr_max-content] relative">

  @if (dataSource) {
    <div class="overflow-auto relative">
      <!-- TABLE -->
      <table mat-table
        [dataSource]="dataSource" matSort
        (matSortChange)="setSortData($event)">
        <!-- HEADERS -->
        @if (navigationColumn && navigationId && navigationUrl) {
          <ng-container matColumnDef="crm_nav" sticky>
            <th mat-header-cell *matHeaderCellDef class="!w-[80px]">
              @if (allowSelection) {
                <button mat-icon-button (click)="toggleSelection$.next(!toggleSelection$.value)">
                  @if (selectionEnabled$ | async) {
                    <mat-icon>close</mat-icon>
                  } @else {
                    <mat-icon >check_box_outline_blank</mat-icon>
                  }
                </button>
              }
            </th>
            <td mat-cell *matCellDef="let element;">
              <div class="flex space-x-1">
                <!--            <button mat-icon-button aria-label="Azioni">-->
                <!--              <mat-icon>more_vert</mat-icon>-->
              <!--            </button>-->
              @if (selectionEnabled$ | async) {
                <mat-checkbox [checked]="false"
                  color="accent"
                  [indeterminate]="false"
                  (change)="addOrRemoveItem($event.checked, element[navigationId])">
                </mat-checkbox>
              } @else {
                <button mat-icon-button aria-label="Dettagli"
                  routerLink="{{navigationUrl.replace(':'+navigationId, element[navigationId])}}">
                  <mat-icon>arrow_circle_right</mat-icon>
                </button>
              }
            </div>
          </td>
        </ng-container>
      }
      @for (columnConfig of config; track columnConfig) {
        <ng-container [matColumnDef]="columnConfig.columnName">
          @if (!columnConfig.notInTable) {
            <th mat-header-cell
              *matHeaderCellDef
              class="header-with-padding"
              [disabled]="!columnConfig.sortable || !!currentResizingColumn"
              mat-sort-header>
              <div class="whitespace-nowrap">
                {{columnConfig.displayName}}
                @if (getTextFilter(columnConfig.columnName).length > 0) {
                  <span
                  class="mat-caption">: {{getTextFilter(columnConfig.columnName)}}</span>
                }
                <!--            <div *ngIf="columnConfig.textFilter" class="icon-filler"></div>-->
                <!--            <div *ngIf="columnConfig.generateFilters" class="icon-filler"></div>-->
                <!--            <div class="icon-filler"></div>-->
              </div>
              <div class="absolute right-0 ">
                <!--          <div class="ml-auto flex items-center">-->
                <!-- TEXT FILTER BUTTON -->
                @if (columnConfig.textFilter) {
                  <button mat-icon-button
                    [matMenuTriggerFor]="searchMenu"
                    (menuOpened)="searchInput.focus()"
                    [disabled]="!!currentResizingColumn"
                    [color]="getTextFilter(columnConfig.columnName).length > 0 ? 'warn' : undefined"
                    (click)="$event.stopPropagation()">
                    <mat-icon>search</mat-icon>
                  </button>
                }
                <!-- TEXT FILTER INPUT -->
                <mat-menu #searchMenu="matMenu">
                  <mat-form-field appearance="outline" class="m-2" (click)="$event.stopPropagation()">
                    <mat-label>Cerca</mat-label>
                    <input matInput #searchInput autofocus
                      (keyup)="dataSource.textFilter$.next({columnName: columnConfig.columnName, textFilter: searchInput.value})"
                      [value]="getTextFilter(columnConfig.columnName)"
                      placeholder="Es. Mario" #input>
                      <button mat-icon-button matSuffix
                        (click)="searchInput.value = ''; dataSource.textFilter$.next({columnName: columnConfig.columnName, textFilter: searchInput.value})"
                        [disabled]="searchInput.value.length === 0"
                        [attr.aria-label]="'Cancella'">
                        <mat-icon>{{searchInput.value.length > 0 ? 'clear' : 'search'}}</mat-icon>
                      </button>
                    </mat-form-field>
                  </mat-menu>
                  <!-- GROUP FILTERS -->
                  @if (columnConfig.generateFilters) {
                    <!-- GROUP FILTER BUTTON -->
                    <button mat-icon-button [matMenuTriggerFor]="filterMenu"
                      [color]="isGroupFilterActive(columnConfig.columnName) ? 'warn' : undefined"
                      (click)="$event.stopPropagation(); selectGroupFilter$.next(columnConfig.columnName)">
                      <mat-icon>filter_list</mat-icon>
                    </button>
                    <!-- GROUP FILTER MENU -->
                    <mat-menu #filterMenu="matMenu" class="!w-[300px]" (closed)="selectGroupFilter$.next(null)">
                      <ng-template matMenuContent>
                        @if (groupsFilterList$ | async; as groups) {
                          <mat-selection-list multiple #groupList
                            (selectionChange)="sendItemsSelection(columnConfig.columnName, groupList.selectedOptions)">
                            @for (f of groups; track f) {
                              <mat-list-option [value]="f"
                                [selected]="isGroupOptionSelected(columnConfig.columnName, f)"
                                (click)="$event.stopPropagation();">
                                <nbd-crm-item-renderer [columnConfig]="columnConfig" [item]="f"></nbd-crm-item-renderer>
                              </mat-list-option>
                            }
                          </mat-selection-list>
                        } @else {
                          <div class="w-full p-4">
                            <mat-progress-bar mode="indeterminate" class="w-full"></mat-progress-bar>
                          </div>
                        }
                      </ng-template>
                    </mat-menu>
                  }
                  <mat-icon class="resize-handle"
                    (click)="$event.stopPropagation()"
                    (mousedown)="startResize(columnConfig.columnName, $event)">drag_handle
                  </mat-icon>
                </div>
              </th>
              <!-- CELL DATA -->
              <td mat-cell *matCellDef="let element" >
                <nbd-crm-item-renderer [rowData]="element" [columnConfig]="columnConfig"
                  [class.opacity-60]="element.deleted"
                  [item]="element[columnConfig.columnName]">
                </nbd-crm-item-renderer>
              </td>
            }
          </ng-container>
        }
        @if (dataSource.visibleColumns$ | async; as visibleColumns) {
          <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: visibleColumns;"></tr>
        }
        <!-- EMPTY DATA ROW -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell p-4 mat-text-warn" colspan="4">Nessun dato</td>
        </tr>
      </table>
    </div>
  }

  <div class="flex justify-between mat-bg-card items-center">

    <!-- ACTION BUTTONS-->
    <div class="grid grid-cols-3 place-items-center gap-2">

      <button mat-icon-button color="primary" (click)="dataSource.refresh$.next(true)" matTooltip="Aggiorna dati">
        <mat-icon>refresh</mat-icon>
      </button>

      <button mat-icon-button color="primary" [matMenuTriggerFor]="columnsMenu" matTooltip="Seleziona colonne">
        <mat-icon>rule</mat-icon>
      </button>

      <button mat-mini-fab color="primary" class="mat-no-shadow" (click)="export$.next()" matTooltip="Esporta Excel">
        <mat-icon>download</mat-icon>
      </button>

      <!-- SHOW COLUMNS MENU -->
      <mat-menu #columnsMenu="matMenu" class="!max-w-none">
        @if (auth.role() !== 'crm-sgr') {
          <div class="p-4">
            <mat-slide-toggle color="warn"
              [checked]="dataSource.showDeleted$ | async"
            (change)="dataSource.showDeleted$.next($event.checked)">Mostra eliminati</mat-slide-toggle>
          </div>
          <mat-divider></mat-divider>
        }

        <mat-selection-list (selectionChange)="setSelectedColumns()"
          class="!grid grid-rows-8 grid-flow-col auto-cols-max">
          @for (c of config; track c) {
            @if (!c.notInTable && !c.disabledFor?.includes(auth.role())) {
              <mat-list-option class="border-r"
                [(selected)]="c.visible"
                [disabled]="!!c.isIndex"
                (click)="$event.stopPropagation();">
                {{c.displayName}}
              </mat-list-option>
            }
          }

        </mat-selection-list>
        <mat-divider></mat-divider>
        <button mat-menu-item
          (click)="$event.stopPropagation();selectAll(true)">
          <mat-icon>done_all</mat-icon>
          <span>Seleziona tutto</span>
        </button>
        <button mat-menu-item
          (click)="$event.stopPropagation();selectAll(false)">
          <mat-icon>remove_done</mat-icon>
          <span>Deseleziona tutto</span>
        </button>
      </mat-menu>
    </div>

    <mat-paginator [pageSizeOptions]="[25, 50, 100, 250]"
      (page)="setPageData($event)"
      [length]="dataSource.data$.value.count"
      aria-label="Seleziona la pagina">
    </mat-paginator>
  </div>


  @if (dataSource.loading$ | async) {
    <div class="w-full h-full grid place-items-center absolute top-0 left-0 bg-gray-100/80 z-[1000]"
      >
      <nbd-loader></nbd-loader>
    </div>
  }

</div>
