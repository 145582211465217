<ng-container *ngIf="columnConfig.render; else cellTemplate">
  {{columnConfig.render(item, rowData)}}
</ng-container>
<ng-template #cellTemplate [ngSwitch]="columnConfig.type">
  <ng-container *ngSwitchCase="columnType.Default">
    {{item | null}}
  </ng-container>
  <ng-container *ngSwitchCase="columnType.Boolean">
    {{item | boolean}}
  </ng-container>
  <ng-container *ngSwitchCase="columnType.Date">
    <ng-container *ngIf="item | toNull as item; else itemNull">
      {{item | date:'shortDate'}}
    </ng-container>
    <ng-template #itemNull>
      --
    </ng-template>

  </ng-container>
  <ng-container *ngSwitchCase="columnType.Time">
    <ng-container *ngIf="item | toNull as item; else itemNull">
      {{item | date:'mediumTime'}}
    </ng-container>
    <ng-template #itemNull>
      --
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="columnType.DateTime">
    <ng-container *ngIf="item | toNull as item; else itemNull">
      {{item | date:'short'}}
    </ng-container>
    <ng-template #itemNull>
      --
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="columnType.JSON">
    <button mat-flat-button (click)="showJsonDialog({title: columnConfig.displayName, json: item})">VISUALIZZA</button>
  </ng-container>
  <ng-container *ngSwitchCase="columnType.JSONArray">
      <mat-chip-set *ngIf="Array.isArray(parsedItem); else plainOutput">
        <mat-chip *ngFor="let i of parsedItem">{{i | null}}</mat-chip>
      </mat-chip-set>
    <ng-template #plainOutput>{{item | null}}</ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="columnType.Array">
    <mat-chip-set *ngIf="Array.isArray(item); else plainOutput">
      <mat-chip *ngFor="let i of item">{{i | null}}</mat-chip>
    </mat-chip-set>
    <ng-template #plainOutput>{{item | null}}</ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="columnType.ID">
    <ng-container *ngIf="rowData.deleted; else normalId">
      <div class="flex items-center space-x-1">
        <mat-icon color="warn" >remove_circle</mat-icon>
        <span>({{item}})</span>
      </div>
    </ng-container>
    <ng-template #normalId>
      {{item}}
    </ng-template>

  </ng-container>
</ng-template>
