<div class="grid grid-rows-[max-content_max-content] relative
rounded overflow-hidden mat-bg-card mat-elevation-z1 cursor-pointer"
  (click)="selected.next(image)">
  <div class="aspect-square w-full checkerboard grid place-items-center">
    <img src="{{environment.assetsBucketUrl}}/{{image.key}}" alt="{{image.name}}">
  </div>
  <div class="w-full flex flex-col p-2 ">
    <div><strong>{{image.name}}</strong></div>
    <div class="w-full flex justify-between"><span class="mat-caption">{{image.modified | date:'short'}}</span>
    <span>{{image.size | fileSize}}</span></div>
  </div>
  @if (isSelected) {
    <div class="absolute top-0 left-0 w-full h-full mat-bg-accent opacity-20"></div>
  }
</div>
