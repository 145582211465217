<h1 mat-dialog-title>Seleziona un'immagine</h1>
<div mat-dialog-content class="mat-app-background">
  @if (images$ | async; as images) {
    <div class="grid image-grid gap-4">
      @for (i of images; track i) {
        <nbd-image-gallery-item
          (selected)="selectedImage = $event"
          [isSelected]="i == selectedImage"
          [image]="i">
        </nbd-image-gallery-item>
      }
    </div>
  } @else {
    <nbd-loader [fill]="true"></nbd-loader>
  }
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button
    [mat-dialog-close]="environment.assetsBucketUrl + '/' + selectedImage?.key"
    [disabled]="!selectedImage"
    color="primary">
    OK
  </button>
  <button mat-button [mat-dialog-close]="null">Annulla</button>
</div>
