import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from './pipes/safe.pipe';
import {ErrorDialogComponent} from './components/dialogs/error-dialog/error-dialog.component';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {InfoDialogComponent} from './components/dialogs/info-dialog/info-dialog.component';
import {LoaderComponent} from './components/loader/loader.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HttpClientModule} from '@angular/common/http';
import {TableComponent} from './table/table.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {CrmItemRendererComponent} from './table/crm-item-renderer/crm-item-renderer.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ImageGalleryComponent} from './components/image-gallery/image-gallery.component';
import {ImageGalleryItemComponent} from './components/image-gallery/image-gallery-item/image-gallery-item.component';
import {FileSizePipe} from './pipes/file-size.pipe';
import {IsoToDatePipe} from './pipes/iso-to-date.pipe';
import {BooleanPipe} from './pipes/boolean.pipe';
import { NullPipe } from './pipes/null.pipe';
import { JsonViewerDialogComponent } from './table/json-viewer-dialog/json-viewer-dialog.component';
import { DotToValuePipe } from './pipes/dot-to-value.pipe';
import {SaveQueryDialogComponent} from './components/dialogs/save-query-dialog/save-query-dialog.component';
import {FormsModule} from '@angular/forms';
import { UserKeywordsComponent } from './components/user-keywords/user-keywords.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ToNullPipe } from './pipes/to-null.pipe';
import { CheckLifecycleDirective } from './directives/check-lifecycle.directive';
import { SelectDialogComponent } from './components/dialogs/select-dialog/select-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    SafePipe,
    ErrorDialogComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    SaveQueryDialogComponent,
    LoaderComponent,
    TableComponent,
    CrmItemRendererComponent,
    ImageGalleryComponent,
    ImageGalleryItemComponent,
    FileSizePipe,
    IsoToDatePipe,
    BooleanPipe,
    NullPipe,
    JsonViewerDialogComponent,
    DotToValuePipe,
    UserKeywordsComponent,
    ToNullPipe,
    CheckLifecycleDirective,
    SelectDialogComponent
  ],
    exports: [
        LoaderComponent,
        TableComponent,
        IsoToDatePipe,
        FileSizePipe,
        SafePipe
    ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatSnackBarModule,
    CdkDrag,
    CdkDragHandle,
    MatProgressBarModule,
    MatTooltipModule,
    MatChipsModule,
    FormsModule,
    ClipboardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
  ]
})
export class SharedModule {
  // constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
  //   console.log("Shared Module init");
  //   if (parentModule) {
  //     throw new Error(
  //       'SharedModule is already loaded. Import it in the AppModule only');
  //   }
  // }
}
