<div class="grid place-items-center p-5" [ngClass]="{
'w-full': fill,
'h-full': fill
}">
  @if(mode === 'bar') {
    <mat-progress-bar mode="indeterminate" [color]="color"></mat-progress-bar>
  } @else {
    <mat-spinner mode="indeterminate" [color]="color"></mat-spinner>
  }

</div>
