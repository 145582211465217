import {Injectable} from '@angular/core';
import {catchError, of, switchMap} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrmDataRequest} from './table/models/CrmDataRequest';
import {CrmDataResult} from './table/models/CrmDataResult';
import {CrmGroupRequest} from './table/models/CrmGroupRequest';
import {map, shareReplay} from 'rxjs/operators';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {environment} from '../../environments/environment';
import {ColumnConfig} from './table/models/ColumnConfig';
import {GalleryImage} from './models/gallery-image';
import {HelperService} from './helper.service';
import {debug} from './rxjs/debug';
import {SavedQuery} from '../users/classes/saved-query';
import {notNullOrUndefined} from './rxjs/notNullOrUndefined';
import {isTrue} from "./rxjs/isTrue";

@Injectable({
  providedIn: 'root'
})
export class CrmDataService {

  queryLists$ = this.http.get<{name: string, id: number}[]>(`${environment.apiUrl}/crm/messenger/available-lists`);
  userLists$ = this.http.get<{name: string, id: number}[]>(`${environment.apiUrl}/crm/user-lists`);
  mealTables$ = this.http.get<any>(`${environment.apiUrl}/crm/meal-tables/meals-dropdown`);
  emailTemplates$ = this.http.get<{templateKey: string, id: number}[]>(`${environment.apiUrl}/crm/messenger/available-templates`);
  availableSgr$ = this.http.get<{label: string, value: any}[]>(`${environment.apiUrl}/crm/sgr/available`);

  constructor(private http: HttpClient, private hs: HelperService) {

  }

  fetchData(url: string, request: CrmDataRequest) {
    const q = CrmDataService.getBase64Request(request);
    return this.http.get<CrmDataResult>(`${url}`, {
      params: new HttpParams().set('q', q)
    }).pipe(
      map((res) => {
        res.query = q;
        return res;
      })
    );
  }

  fetchGroups(url: string, request: CrmGroupRequest) {
    return this.http.get<string[]>(`${url}`, {
      params: new HttpParams().set('q', CrmDataService.getBase64Request(request))
    });
  }

  fetchExport(url: string, request: CrmDataRequest) {
    return this.http.get<{ fileName: string; url: string; }>(`${url}`, {
      params: new HttpParams().set('q', CrmDataService.getBase64Request({
        ...request,
        export: true
      }))
    });
  }

  public static getBase64Request(request: any) {
    return btoa(JSON.stringify(request));
  }

  getFormTemplate(key: string) {
    return this.http.get<FormlyFieldConfig[]>(`${environment.apiUrl}/crm/form-editor/${key}`);
  }

  getTableConfig(key: string) {
    return this.http.get<ColumnConfig[]>(`${environment.apiUrl}/crm/table-config/${key}`).pipe(
      catchError((err) => of(null)),
      map((res) => res || [])
    );
  }

  getImageGallery() {
    return this.http.get<{items: GalleryImage[], name: string; type: string}[]>(`${environment.apiUrl}/crm/image-gallery`).pipe(
      this.hs.httpErrorHandler([]),
      map((res) => {
        if(res.length > 0) {
          return res[0].items;
        }
        return [];
      }),
      debug('Image Gallery'),
    );
  }

  getSavedQueries(table?: string) {
    return this.http.get<SavedQuery[]>(`${environment.apiUrl}/crm/saved-queries${table ? '/'+table : ''}`).pipe(
      this.hs.httpErrorHandler([]),
      shareReplay(1)
    );
  }

  saveQuery(query: {queryData: string, tableName: string}) {
    return this.hs.saveQuery().pipe(
      notNullOrUndefined(),
      debug('Saving query'),
      switchMap((name) => this.http.post<SavedQuery>(`${environment.apiUrl}/crm/saved-queries`, {
        ...query,
        name
      }).pipe(
        this.hs.httpErrorHandler({})
      ))
    );
  }

  updateQuery(query: SavedQuery) {
    return this.hs.saveQuery(query.name).pipe(
      notNullOrUndefined(),
      debug('Updating query'),
      switchMap((name) => this.http.put<SavedQuery>(`${environment.apiUrl}/crm/saved-queries/${query.id}`, {
        ...query,
        name
      }).pipe(
        this.hs.httpErrorHandler({})
      ))
    );
  }

  deleteQuery(query: SavedQuery) {
    return this.hs.confirm('Elimina filtro', `Vuoi eliminare il filtro chiamato "${query.name}"?`).pipe(
      isTrue(),
      switchMap((name) => this.http.delete<any>(`${environment.apiUrl}/crm/saved-queries/${query.id}`,).pipe(
        this.hs.httpErrorHandler({})
      ))
    );
  }

  saveUserList(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/crm/user-lists`, data).pipe(
      this.hs.httpErrorHandler(null)
    );
  }

  assignListToFlight(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/crm/transfers/list-to-flight`, data).pipe(
      this.hs.httpErrorHandler(null)
    );
  }

}
