import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GalleryImage} from '../../../models/gallery-image';
import {environment} from '../../../../../environments/environment';
import {Subject} from 'rxjs';

@Component({
  selector: 'nbd-image-gallery-item',
  templateUrl: './image-gallery-item.component.html',
  styleUrls: ['./image-gallery-item.component.scss']
})
export class ImageGalleryItemComponent {
  @Input() image!: GalleryImage;
  @Input() isSelected = false;

  @Output() selected = new EventEmitter<GalleryImage>();

  protected readonly environment = environment;
}
